import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page Not Found" />
    <section className="not-found">
      <div className="container">
        <div className="grid center-text">
          <div className="column-xs-12">
            <h4>Page Not Found</h4>
            <h1>404</h1>
            <p className="lead">Sorry, but the page you were looking for could not be found.</p>
            <Link to='/' className="button" role="button">Go to Home <span className="icon arrow" aria-hidden="true"></span></Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
